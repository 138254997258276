import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { t } from '../localization';
import { DIMS } from '../capture/shape';
import { ROUTES, LoadMethod, type ImgData } from '../constants';

import classes from './style.module.scss';

interface FaceReadingAnalyzingProps {
  eventCategory: string;
  onCaptureSuccess: (data: ImgData) => void;
  onUploadSuccess: (data: ImgData) => void;
  captureEventCategory: string;
  uploadEventCategory: string;
}

const tKey = 'approve';

export const FaceReadingApprove: React.FC<FaceReadingAnalyzingProps> = ({
  eventCategory,
  onCaptureSuccess,
  onUploadSuccess,
  uploadEventCategory,
  captureEventCategory,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const img = location?.state?.img;
  const type = location?.state?.type;
  const width = location?.state?.width;
  const height = location?.state?.height;
  const isFullScreen = location?.state?.isFullScreen;

  useEffect(() => {
    Analytics.trackEvent(eventCategory, 'open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackClick = () => {
    const isCaptureLoadMethod = type === LoadMethod.CAPTURE;
    const event = isCaptureLoadMethod ? captureEventCategory : uploadEventCategory;
    const rout = isCaptureLoadMethod ? ROUTES.CAPTURE : ROUTES.UPLOAD;
    Analytics.trackEvent(eventCategory, 'not_approve');
    navigate(`..${rout}`, { relative: 'path', replace: true, state: { retake: true  } });
    Analytics.trackEvent(event, 'open');
  };

  const handleButtonClick = () => {
    Analytics.trackEvent(eventCategory, 'success');
    if (type === LoadMethod.CAPTURE) {
      onCaptureSuccess({ img: img, width, height, isFullScreen })
    } else {
      onUploadSuccess({ img: img, ...DIMS, isFullScreen })
    }
  };

  return (
    <div className={classes.wrapper}>
      <img src={img} alt="face" width={DIMS.width} />
      <div className={classNames(classes.footer, { [classes.full_screen]: isFullScreen })}>
        <Button
          flare
          title={t(`${tKey}.button`)}
          onClick={handleButtonClick}
          iconRight={false}
        />
        {
          <LinkButton
            className={classes.back}
            onClick={handleBackClick}
          >
            {t(`${tKey}.back`)}
          </LinkButton>
        }
      </div>
    </div>
  );
};
