import React, { useMemo } from 'react';

import { Title, Text } from '@web-solutions/core/ui-elements';

import { t } from '../../../../localization';

import { ReactComponent as IconCheck } from './icons/check.svg';

import classes from './style.module.scss';

const tKey = 'manage.main.features';

export const Features: React.FC = () => {

  const features = useMemo(() => t(`terminate.confirm.features`, { returnObjects: true }) as any as Array<{ title: string, subtitle: string }>, []);

  return (
    <div className={classes.wrapper}>
      <Title level={'h2'}>{t(`${tKey}.title`)}</Title>
      <div className={classes.content}>
        <div className={classes.warning}>
          {t(`${tKey}.warning`)}
        </div>
        {!!features?.length && (
          <div className={classes.features}>
            {
              features.map(item => (
                <div className={classes.item} key={item.title}>
                  <IconCheck className={classes.icon} />
                  <div>
                    <Title level={'h2'} className={classes.title}>{item.title}</Title>
                    <Text className={classes.subtitle}>{item.subtitle}</Text>
                  </div>
                </div>
              ))
            }
          </div>
        )}
      </div>
    </div>
  )
};
