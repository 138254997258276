import _debounce from 'lodash/debounce';
import { useEffect, useState, useCallback } from "react";

const debounceTime = /Android/i.test(navigator.userAgent) ? 50 : 0;

export const useWindowEnd = () => {
  const [isEnd, setIsEnd] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(_debounce(() => {
    if (window.innerHeight + window.pageYOffset > document.body.scrollHeight - 10) {
      setIsEnd(true);
    }
    else {
      setIsEnd(false);
    }
  }, debounceTime), []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return { isEnd, setIsEnd, handleScroll };
};