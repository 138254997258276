import React from 'react';

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';

const tKey = 'core.injected_payment_method.header';

interface Props {
  isCentralHeader?: boolean;
}

const InjectedPaymentMethodHeader: React.FC<Props> = ({ isCentralHeader = false }) => (
  <div className={classes.header}>
    {
      isCentralHeader ? (
        <div className={classes.subtitleCentral}>{t(`${tKey}.subtitle`)}</div>
      ) : (
        <>
          <div className={classes.title}>{t(`${tKey}.title`)}</div>
          <div className={classes.subtitle}>{t(`${tKey}.subtitle`)}</div>
        </>
      )
    }
  </div>
);

export default InjectedPaymentMethodHeader;
