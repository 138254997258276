import Lottie from 'lottie-react-web';

import rate from '../../animations/RateBig.json';

interface RateProps {
  isFaceDetected: boolean;
}

export const RateBigAnimation: React.FC<RateProps> = ({ isFaceDetected }) => {
  return <>
    {
      isFaceDetected ?
        <Lottie
          width={'300px'}
          height={'90px'}
          style={{
            margin: 0,
            transform: 'scale(1.5)'
          }}
          options={{ animationData: rate }}
        />
        :
        null
    }
  </>
}