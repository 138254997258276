import React, { useRef } from 'react';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { t } from '../../localization';
import { DIMS } from '../../capture/shape';
import { LoadMethod, type ImgData } from '../../constants';

import { ReactComponent as Close } from '../icons/close.svg';

import { ReactComponent as FaceIcon } from './images/face.svg';

import classes from './style.module.scss';

interface UploadPopupProps {
  tKey: string;
  isErrorPage?: boolean;
  onSuccess: (data: ImgData, type: LoadMethod) => void;
  onCloseClick: () => void;
  onSkipClick: () => void;
}

export const UploadPopup: React.FC<UploadPopupProps> = ({
  tKey,
  isErrorPage = false,
  onSuccess,
  onCloseClick,
  onSkipClick,
}) => {
  const refCanvas = useRef<HTMLCanvasElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleBtnClick = () => {
    fileInput?.current?.click();
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (file) {
      const ctx = refCanvas.current?.getContext('2d');

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const scale = Math.max(DIMS.width / img.width, DIMS.height / img.height);
        const dx = (DIMS.width / 2) - (img.width / 2) * scale;
        const dy = (DIMS.height / 2) - (img.height / 2) * scale;
        ctx?.drawImage(img, dx, dy, img.width * scale, img.height * scale);
        const dataURL = refCanvas.current?.toDataURL() || '';
        onSuccess({ img: dataURL, ...DIMS }, LoadMethod.UPLOAD);
      }
    }
  }

  return (
    <div className={classes.popup}>
      <Close
        className={classes.popup__close}
        onClick={onCloseClick}
      />
      {
        !isErrorPage && (
          <FaceIcon
            className={classes.popup__image}
          />
        )
      }
      <div className={classes.popup__title}>{t(`${tKey}.title`)}</div>
      <div className={classes.popup__text}>{t(`${tKey}.text`)}</div>
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        className={classes.popup__input}
        accept="image/*"
      />
      <Button
        flare
        title={t(`${tKey}.button_title`)}
        onClick={handleBtnClick}
        iconRight
        className={classes.popup__button}
      />
      {
        isErrorPage && (
          <LinkButton
            className={classes.popup__skip}
            onClick={onSkipClick}
          >
            {t(`${tKey}.skip`)}
          </LinkButton>
        )
      }
      <canvas
        ref={refCanvas}
        width={DIMS.width}
        height={DIMS.height}
        className={classes.popup__canvas}
      />
    </div>
  );
};
