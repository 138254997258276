import { useState, useEffect, useCallback } from 'react';

import { PaymentSystem } from '../constants';
import { getPaymentSystem } from '../utils/storage';

import usePayment from './use-payment';

const useApplePay = (options = {}, callbacks = {}) => {
  const paymentSystem = getPaymentSystem();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(null);

  const payment = usePayment();

  const handleError = (error) => {
    console.log('[APPLE PAY ERROR]', error);
  };

  const handleAddress = useCallback(
    (e) => {
      if (callbacks?.onShippingAddress) {
        callbacks.onShippingAddress(e?.payment?.shippingContact);
      }
    },
    [callbacks?.onShippingAddress]
  );

  useEffect(() => {
    if (paymentSystem === PaymentSystem.SOLIDGATE) {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments) {
        try {
          setCanMakePayment(window.ApplePaySession.canMakePayments());
        } catch (ex) {
          console.warn(ex);
        }
      }
    }
  }, [paymentSystem]);

  useEffect(() => {
    if (payment && options.currency) {
      let request = null;
      let paymentOptions = {
        country: options.country || 'US',
        currency: options.currency,
      };

      if (paymentSystem === PaymentSystem.RECURLY) {
        paymentOptions = {
          ...paymentOptions,
          label: options.label,
          total: String(options.amount),
        };
        try {
          request = payment.ApplePay(paymentOptions);
          setPaymentRequest(request);
        }
        catch (ex) { }
      } else if (paymentSystem === PaymentSystem.STRIPE) {
        paymentOptions = {
          ...paymentOptions,
          total: {
            label: options.label,
            amount: options.amount,
          },
        };

        request = payment.paymentRequest(paymentOptions);
        setPaymentRequest(request);
      } else if (paymentSystem === PaymentSystem.SOLIDGATE) {
        paymentOptions = {
          ...paymentOptions,
          label: options.label,
          amount: String(options.amount),
        };
        try {
          request = payment.ApplePay(paymentOptions);
          setPaymentRequest(request);
        }
        catch (ex) {
          console.warn(ex);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSystem, options]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      if (paymentSystem === PaymentSystem.RECURLY) {
        paymentRequest.ready(() => {
          if (subscribed) {
            setCanMakePayment(true);
          }
        });
      } else if (paymentSystem === PaymentSystem.STRIPE) {
        paymentRequest.canMakePayment().then((res) => {
          if (res && subscribed) {
            setCanMakePayment(res);
          }
        });
      } else if (paymentSystem === PaymentSystem.SOLIDGATE) {
        paymentRequest.canMakePayment().then((res) => {
          if (res && subscribed) {
            setCanMakePayment(res);
          }
        });
      }
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest, paymentSystem]);

  useEffect(() => {
    if (paymentRequest) {
      const event =
        paymentSystem === PaymentSystem.RECURLY
          ? 'token'
          : paymentSystem === PaymentSystem.STRIPE
            ? 'paymentmethod'
            : paymentSystem === PaymentSystem.SOLIDGATE
              ? 'paymentmethod'
              : '';

      if (paymentSystem === PaymentSystem.RECURLY) {
        paymentRequest.on('error', handleError);
        paymentRequest.on('paymentAuthorized', handleAddress);
      }

      if (callbacks?.onMethod) {
        paymentRequest.on(event, callbacks?.onMethod);
      }

      return () => {
        if (paymentSystem === PaymentSystem.RECURLY) {
          paymentRequest.off('error', handleError);
          paymentRequest.off('paymentAuthorized', handleAddress);
        }

        if (callbacks?.onMethod) {
          paymentRequest.off(event, callbacks?.onMethod);
        }
      };
    }
  }, [paymentRequest, callbacks?.onMethod, paymentSystem, handleAddress]);

  return {
    canMakePayment,
    paymentRequest: canMakePayment ? paymentRequest : null,
  };
};

export default useApplePay;
