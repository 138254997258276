import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

export const DIMS = { width: window.innerWidth, height: Math.round(window.innerHeight * 0.75) };
export const WIDTH_OFFSET = (DIMS.width - DIMS.height) / 2;

export const CameraShape = ({ className }) => {
  return <div id="face-shape" className={classNames(classes.shape, className)} />
}
