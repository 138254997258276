const urlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export async function process(imgUrl) {
  const formData = new FormData();

  formData.append('file', await urlToFile(imgUrl, 'image.jpg', 'image/jpg'));

  return fetch(`https://hand-detection.astroline.today/face/`, {
    method: 'POST',
    body: formData,
  }).then(r => r.json())
}
