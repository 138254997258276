import Lottie from 'lottie-react-web';
import classNames from 'classnames';

import classes from '../../style.module.scss';

import timer from '../../animations/timer.json';

interface TimerProps {
  isFaceDetected: boolean;
  timeLeft: number;
  baseTime: number;
}

export const TimerAnimation: React.FC<TimerProps> = ({ isFaceDetected, baseTime, timeLeft }) => {
  const speed = 5000 / baseTime

  return <div className={classNames(classes.lottie_wrap, { [classes.two_digit]: timeLeft >= 9500 })}>
    <span>{(timeLeft / 1000).toFixed(0)}</span>
    {isFaceDetected ?
      <Lottie
        width={'12vh'}
        height={'12vh'}
        speed={isFaceDetected ? speed : 0}
        options={{ animationData: timer, loop: false }}
      />
      :
      <div className={classes.placeholder} />}
  </div>
}