export const ROUTES = {
  CAPTURE: '/capture',
  ANALYZING: '/analyzing',
  UPLOAD: '/upload',
  APPROVE: '/approve',
};

export enum LoadMethod {
  CAPTURE = 'capture',
  UPLOAD = 'upload',
}

export type ImgData = {
  img: string,
  width: number,
  height: number,
  isFullScreen?: boolean,
}
