import React from 'react';
import classNames from 'classnames';

//@ts-ignore
import { Title } from 'core/ui-elements';

import { t } from '../../../../localization';

import classes from './style.module.scss';

interface Props {
  progress: number,
}

const tKey = 'analyzing';




export const ColumnsProgressDescription: React.FC<Props> = ({ progress }) => {
  const stages = [
    { step: 0, title: t(`${tKey}.column_progress.examining`) },
    { step: 16, title: t(`${tKey}.column_progress.pinpointing`) },
    { step: 49, title: t(`${tKey}.column_progress.scanning`) },
    { step: 66, title: t(`${tKey}.column_progress.analyzing`) },
    { step: 83, title: t(`${tKey}.column_progress.identifying`) },
    { step: 99, title: t(`${tKey}.column_progress.detecting`) },
  ];
  return (
    <div className={classes.wrapper}>
      {
        stages.map(({ step, title }) => {
          const isActiveElement = progress >= step;
          const blockClass = classNames(classes.block, { [classes.active]: isActiveElement });

          return (
            <div className={blockClass}>
              <div className={classes.icon} />
              <Title key={title} className={classes.title}>
                {title}
              </Title>
            </div>
          )
        })
      }
    </div>
  );
};
