import Lottie from 'lottie-react-web';

import { Text } from '@web-solutions/core/ui-elements';

import rate from '../../animations/HeartRateLine.json';

import classes from '../../style.module.scss';
import { t } from '../../../localization';

const tKey = 'capture.animations.heart_rate';

interface HeartRateLineProps {
  isFaceDetected: boolean;
  speed: number;
}

export const HeartRateLineAnimation: React.FC<HeartRateLineProps> = ({ isFaceDetected, speed }) => {
  return <>
    {isFaceDetected ?
      <div className={classes.heart_rate}>
        <Text>{t(`${tKey}.title`)}</Text>
        <Lottie
          width={'265px'}
          speed={speed}
          style={{
            margin: 0,
          }}
          options={{ animationData: rate, loop: false }}
        />
      </div>
      :
      null
    }
  </>
}