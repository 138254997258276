import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { T, t, } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { createVATPrice } from 'core/utils/create-vat-price';

import { SUPPORT_EMAIL } from 'src/constants/general';

import classes from './style.module.scss';

const PaymentDescription = ({
  activeProduct,
  isShort,
}) => {
  const {
    priceText,
    name,
    expireDate,
    introExpireDate,
    weekPrice,
    isTrial,
    trialPeriodDays,
    trialPeriodPrice,
    isIntroductory,
    period,
    currency,
    amount,
  } = activeProduct;

  const { policyLinks, isOldPaymentDescription, displayVAT } = useSelector(remoteConfigSelector);
  const { vat, price } = createVATPrice(amount, currency);

  const priceString = displayVAT ? `${price} + ${vat}` : priceText
  
  const todayPrice = isTrial ? trialPeriodPrice : priceText;

  const baseKey = isOldPaymentDescription ? 'v_first' : 'v_second';

  let tKey = !isShort ? `core.${baseKey}.payment_description` : `core.${baseKey}.payment_description_short`;

  if (isTrial && !isIntroductory) {
    tKey = `${tKey}_trial`;
  }

  if (isIntroductory) {
    tKey = `${tKey}_intr`;
  }

  return (
    <p className={classNames(classes.root, { [classes.short]: isShort }, 'description_light')}>
      <T
        k={tKey}
        tOptions={{
          plan: name,
          expireDate,
          introExpireDate,
          priceText: priceString,
          weekPrice,
          todayPrice,
          trialDay: trialPeriodDays,
          period: t(`core.payment_popup.price_texts.${period}`),
        }}
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        components={{ mblink: <a href={policyLinks.moneyBack} /> }}
      />
      <span> </span>
      <T
        k={`core.${baseKey}.payment_description_contact_us`}
      />
      <span> </span>
      <a
        href={`mailto:${SUPPORT_EMAIL}`}
        className={classes.link}
      >
        {SUPPORT_EMAIL}
      </a>
      {policyLinks.descriptor && (
        <>
          <span> </span>
          <T
            k={`core.${baseKey}.payment_description_bank_statement`}
            tOptions={{
              descriptor: policyLinks.descriptor,
            }}
          />
        </>
      )}
      {policyLinks.phone && (
        <>
          <span> </span>
          <T
            k={`core.${baseKey}.payment_description_phone`}
          />
          <span> </span>
          <a
            href={`tel:${policyLinks.phone}`}
            className={classes.link}
          >
            {policyLinks.phone}
          </a>
        </>
      )}
      {isShort
        && (
          <span className={classes.more}>
            <T
              k={`core.${baseKey}.payment_description_short_more`}
            />
            <br />
            <a
              href={policyLinks.billingTerms}
              target="__blank"
              className={classes.link}
            >
              <T k={`core.${baseKey}.payment_description_short_link`} />
            </a>
          </span>
        )}
    </p>
  );
};

PaymentDescription.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  isShort: PropTypes.bool,
  className: PropTypes.any,
};

export default PaymentDescription;
