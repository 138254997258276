import classes from '../../style.module.scss';

import { HeartAnimation } from './heart';
import { TimerAnimation } from './timer';
import { RateAnimation } from './rate';


interface AnimationsProps {
  isFaceDetected: boolean;
  timeLeft: number;
  speed: number;
  baseTime: number;
}

export const Animations: React.FC<AnimationsProps> = ({ isFaceDetected, timeLeft, speed, baseTime }) => {
  return <div className={classes.animations}>
    <RateAnimation isFaceDetected={isFaceDetected} />
    <TimerAnimation baseTime={baseTime} isFaceDetected={isFaceDetected} timeLeft={timeLeft} />
    <HeartAnimation className={classes.heart} speed={speed} isFaceDetected={isFaceDetected} />
  </div>
}