import React from 'react';
import Modal from 'react-modal';

import { Title, Button, LinkButton, } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  visible: boolean;
  title: string;
  subTitle: string;
  btnTitle: string;
  skipTitle: string;
  onClose: () => void;
  onSkipClick: () => void;
}

const ErrorPopUp: React.FC<Props> = ({
  visible,
  title,
  subTitle,
  btnTitle,
  skipTitle,
  onClose,
  onSkipClick,
}) => (
  <Modal
    isOpen={visible}
    className={classes.modal}
    overlayClassName={classes.overlay}
  >
    <Title className={classes.title}>{title}</Title>
    <div className={classes.subtitle}>{subTitle}</div>
    <Button
      flare={false}
      onClick={onClose}
      iconRight={false}
      className={classes.button}
      title={btnTitle}
      titleClassName={classes.button__title}
    />
    {skipTitle && (
      <LinkButton
        className={classes.skip}
        onClick={onSkipClick}
      >
        {skipTitle}
      </LinkButton>
    )}
  </Modal>
);

export default ErrorPopUp;
