import Lottie from 'lottie-react-web';
import classNames from 'classnames';

import timer from '../../animations/TimerFull.json';

import classes from '../../style.module.scss';

interface TimerProps {
  isFaceDetected: boolean;
  timeLeft: number;
  speed: number;
}

export const TimerFullAnimation: React.FC<TimerProps> = ({ isFaceDetected, timeLeft, speed }) => {
  return <>
    {isFaceDetected ? <div className={classNames(classes.full_timer, { [classes.two_digit]: timeLeft >= 9500 })}>
      <span>{(timeLeft / 1000).toFixed(0)}</span>
      <Lottie
        width={'70px'}
        height={'70px'}
        speed={speed}
        style={{
          margin: 'auto 10px auto auto'
        }}
        options={{ animationData: timer, loop: false }}
      />
    </div>
      :
      null
    }
  </>
}