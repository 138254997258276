import { createSlice } from '@reduxjs/toolkit';

export const faceReadingSlice = createSlice({
  name: 'faceReading',
  initialState: {
    faceImg: null,
    faceImgDims: null,
    faceKeyPoints: null,
  },
  reducers: {
    setFaceData: (state, action) => ({
      ...state,
      faceImg: action.payload.img,
      faceImgDims: { width: action.payload.width, height: action.payload.height, isFullScreen: action.payload.isFullScreen },
      faceKeyPoints: action.payload.keypoints,
    }),
  },
})

export const { setFaceData } = faceReadingSlice.actions;

export const selectFaceImg = state => state.faceReading.faceImg;
export const selectFaceImgDims = state => state.faceReading.faceImgDims;
export const selectFaceKeyPoints = state => state.faceReading.faceKeyPoints;
