import React, { useState } from 'react';
import Modal from 'react-modal';

import { Title, Button } from 'core/ui-elements';

import { t } from '../../../localization';

import { ReactComponent as IconClose } from '../icons/close-icon.svg';

import classes from './style.module.scss';

interface ReasonPopUpProps {
isOpen: boolean,
onClose: () => void,
onSubmit: (value: string) => void,
}

const tKey = 'manage.reason.popup';

const ReasonPopUp: React.FC<ReasonPopUpProps> = ({
isOpen,
onClose,
onSubmit
}) => {
const [reasonPopupValue, setReasonPopupValue] = useState('');

const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
setReasonPopupValue(e.target.value);
}

const handleSubmit = () => {
onSubmit(reasonPopupValue);
}

const handleClose = () => {
onClose();
setReasonPopupValue('');
}

return <Modal
isOpen={isOpen}
className={classes.modal}
overlayClassName={classes.overlay}
>
<Title level="h2" className={classes.title}>{t(`${tKey}.title`)}</Title>
<button type="button" onClick={handleClose} className={classes.close}>
<IconClose width="16px" height="16px" />
</button>
<Title level="h3" className={classes.subtitle}>{t(`${tKey}.subtitle`)}</Title>
<textarea
className={classes.textarea}
value={reasonPopupValue}
onChange={handleChange}
/>
<Button
disabled={!reasonPopupValue}
onClick={handleSubmit}
iconRight={false}
className={classes.button}
title={t(`${tKey}.button`)}
titleClassName={classes.button__title}
/>
</Modal>
};

export default ReasonPopUp;