import React from 'react';
import classNames from 'classnames';

import { useFixedButton } from 'core/hooks/use-fixed-button';

import Button from '../button';

import classes from './style.module.scss';

interface Props {
  text: string;
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  productsListSelector?: string;
  buttonsSelector: string;
  productSelector?: string;
  className?: string;
}

const DynamicFixedButtonBlock: React.FC<Props> = ({ text, onButtonClick, buttonsSelector, className, productsListSelector, productSelector }) => {
  const { fixed } = useFixedButton({
    buttonsSelector,
    productSelector,
    productsListSelector
  });

  return (
    <div className={classNames(classes.fixed, className, {
      [classes.hidden]: !fixed,
    })}
    >
      <Button
        id='fixed'
        title={text}
        titleClassName={classes.button_text}
        className={classes.button}
        onClick={onButtonClick}
      />
    </div>
  )
}

export default DynamicFixedButtonBlock;