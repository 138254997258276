import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from './routes';

import { TerminateMainScreen } from './main';
import { TerminateEmailSentScreen } from './email-sent';
import { TerminateConfirmScreen } from './confirm';
import { TerminateErrorScreen } from './error';
import { TerminateSuccessScreen } from './success';

const SCREENS = {
  [ROUTES.MAIN]: <TerminateMainScreen />,
  [ROUTES.EMAIL_SENT]: <TerminateEmailSentScreen />,
  [ROUTES.CONFIRM]: <TerminateConfirmScreen />,
  [ROUTES.ERROR]: <TerminateErrorScreen />,
  [ROUTES.SUCCESS]: <TerminateSuccessScreen />,
};

export const Terminate = () => (
  <Suspense fallback={<div />}>
    <BrowserRouter>
      <Routes>
        {Object.values(ROUTES).map((r, i) => (
          <Route key={i} path={r} element={SCREENS[r]} />
        ))}
        <Route path='*' element={<Navigate to={ROUTES.MAIN} />} />
      </Routes>
    </BrowserRouter>
  </Suspense>
);
