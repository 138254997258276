import React from 'react';

import { t } from '../../localization';

import { TerminateTemplate } from '../components/template';

const tKey = 'terminate.email_sent';

export const TerminateEmailSentScreen = () => (
  <TerminateTemplate title={t(`${tKey}.title`)} text={t(`${tKey}.description`)} />
);
