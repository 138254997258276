import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import type { OnApproveData } from "@paypal/paypal-js";
import { CreateSubscriptionActions } from '@paypal/paypal-js';
import classNames from 'classnames';

import { PaymentSystem } from '@web-solutions/react-billing';

import { createCustomer } from 'core/store/billing/actions';
import { Preloader } from 'core/ui-elements';


import classes from './style.module.scss';

/**
 * STYLE GUIDE FOR BUTTON
 * https://developer.paypal.com/docs/business/checkout/reference/style-guide
 */

interface Props {
  style?: {},
  planId: string,
  currency: string,
  onSuccess: (data: OnApproveData) => Promise<void>,
  onClick: () => void,
  onCancel: () => void,
  onError: (err: { message: string; code: string; }) => void,
  isDisabled?: boolean,
  className?: string,
}

const DEFAULT_PAYPAL_BUTTON_STYLES = {
  shape: 'rect',
  layout: 'vertical',
  label: 'paypal',
  color: 'gold',
  tagline: 'false',
};

const PayPalBtn: React.FC<Props> = ({
  planId,
  style = DEFAULT_PAYPAL_BUTTON_STYLES,
  className,
  currency,
  isDisabled = false,
  onSuccess,
  onClick,
  onCancel,
  onError,
}) => {
  const [{ options, isPending }, dispatchPayPal] = usePayPalScriptReducer();

  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  useEffect(() => {
    dispatchPayPal({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
        intent: 'subscription',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, planId]);

  const handleCreateSubscription = async (data: Record<string, unknown>, actions: CreateSubscriptionActions) => {
    const customer = await dispatch(createCustomer({ paymentSystem: PaymentSystem.PAYPAL }));
    const idfm = queryString.parse(window.location.search).idfm;
    return actions.subscription.create({
      plan_id: planId,
      //@ts-ignore
      custom_id: `${customer.id}|${Date.now()}|${idfm}`, // Date.now works like hash, just to make id uniq.
    });
  };

  const handleClick = () => {
    setPending(true)
    onClick()
  }

  const handleCancel = () => {
    setPending(false)
    onCancel()
  }

  const handleError = (err: any) => {
    setPending(false)
    onError(err)
  }

  const handleApprove = (data: OnApproveData) => {
    setPending(false)
    return onSuccess(data)
  }

  if (isPending) return null;

  return (
    <>
      <PayPalButtons
        className={classNames(classes.container, className)}
        style={style}
        disabled={isDisabled}
        fundingSource={undefined}
        createSubscription={handleCreateSubscription}
        onClick={handleClick}
        onCancel={handleCancel}
        onApprove={handleApprove}
        onError={handleError}
      />
      {pending && <Preloader />}
    </>
  );
};

export default PayPalBtn;
