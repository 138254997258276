import React from 'react';

import { t } from '../../../localization';

import { TerminateFooter } from '../footer';

import classes from './style.module.scss';

interface TerminateTemplateProps {
  title: React.ReactNode,
  text: React.ReactNode,
  children?: React.ReactNode,
}

export const TerminateTemplate: React.FC<TerminateTemplateProps> = ({ title, text, children }) => (
  <div className={classes.wrap}>
    <div className={classes.top}>
      <h1 className={classes.top_appname}>{t('core.appName', { ns: '' })}</h1>
    </div>
    <div className={classes.texts}>
      <h2 className={classes.texts_title}>{title}</h2>
      <p className={classes.texts_text}>{text}</p>
    </div>
    <div className={classes.bottom}>{children}</div>
    <TerminateFooter />
  </div>
);
