import { useState } from 'react';
import * as dayjs from 'dayjs';

import useInterval from './use-interval';

const prepare = (time) => {
  const dur = dayjs(time).diff(dayjs(), 'seconds');
  const minutes = Math.floor(dur / 60);
  const seconds = dur - minutes * 60;
  return { seconds, minutes };
}

const useTimer = (endTime) => {
  const [data, setData] = useState(prepare(endTime));

  useInterval(() => {
    setData(prepare(endTime))
  }, 1000);

  return data;
};

export default useTimer;
