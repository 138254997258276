import { DIMS, WIDTH_OFFSET } from '../shape';

export class Camera {
  video?: HTMLVideoElement;
  canvas?: HTMLCanvasElement;
  ctx?: CanvasRenderingContext2D;
  widthOffset: number;

  constructor(widthOffset: number) {
    this.video = document.getElementById('video') as HTMLVideoElement;
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.widthOffset = widthOffset
  }

  /**
   * Initiate a Camera instance and wait for the camera stream to be ready.
   * @param cameraParam From app `STATE.camera`.
   */
  static async setupCamera(initTimeout?: number, dims?: { width: number, height: number }) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error(
        'Browser API navigator.mediaDevices.getUserMedia not available');
    }

    const size = dims?.height ?? DIMS.height;

    const widthOffset = !!dims ? (dims.width - dims.height) / 2 : WIDTH_OFFSET;

    const videoConfig = {
      'audio': false,
      'video': {
        facingMode: 'user',
        width: { ideal: size },
        height: { ideal: size },
        frameRate: { ideal: 20, },
      }
    };

    const stream = await Promise.race([
      navigator.mediaDevices.getUserMedia(videoConfig),
      new Promise((resolve, reject) => {
        if (initTimeout) {
          setTimeout(() => {
            reject(Error('timeout'));
          }, initTimeout);
        }
      }),
    ]) as MediaStream;

    const camera = new Camera(widthOffset);

    if (camera.video && camera.canvas) {
      const p = new Promise((resolve, reject) => {
        camera.video!.onloadedmetadata = () => {
          resolve(camera.video);
        };
      });

      camera.video.srcObject = stream;

      try {
        await camera.video.play();
      } catch (ex) { }


      await p;

      try {
        await camera.video.play();
      } catch (ex) { }


      const videoWidth = camera.video.videoWidth;
      const videoHeight = camera.video.videoHeight;
      // Must set below two lines, otherwise video element doesn't show.
      camera.video.width = videoWidth;
      camera.video.height = videoHeight;

      camera.canvas.width = window.innerWidth;
      camera.canvas.height = videoHeight;

      // @ts-ignore
      camera.canvas.style = `width: ${window.innerWidth}px; height: ${videoHeight}px; max-height: ${videoHeight}px; min-height: ${videoHeight}px;`;

    }
    return camera;
  }

  dispose() {
    try {
      // @ts-ignore
      this.video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
      delete this.canvas;
      delete this.video;
      delete this.ctx;
    }
    catch (ex) {
      console.warn(ex);
    }
  }

  drawCtx() {
    if (this.video) {
      this.ctx?.save();
      this.ctx?.translate(Number(this.canvas?.width), 0);
      this.ctx?.scale(-1, 1);
      this.ctx?.translate(0, 0);
      this.ctx?.drawImage(this.video, this.widthOffset, 0, this.video.videoWidth, this.video.videoHeight);
      this.ctx?.restore();
    }
  }
}
