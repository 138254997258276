import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface SignboardProps {
  children: React.ReactNode;
  type: 'success' | 'warning' | 'error';
  className?: string;
}

const Signboard: React.FC<SignboardProps> = ({ children, type, className }) => {

  const classSignboard = classNames(classes.signboard, classes[type], className);

  return (
    <div className={classSignboard}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default Signboard;